import osintAxios from "../../axios/osint-axios";
import delvAxios from "../../axios"
import blackboxAxios from "../../blackbox-axios"

export async function getCompanySearchQueryId(payload) {
    const url = "/api/v1/company/search/sources";
    return await osintAxios.post(url, payload, { headers: { "x-case-id": payload.neo_case_id, "x-status": "RUNNING" } });
}

export async function getSearchedRecords(payload) {
    const url = "/api/v1/company/search";
    return await osintAxios.post(url, payload, { headers: { "x-tool-name": "company-name", "x-case-id": payload.neo_case_id, "x-status": "RUNNING" } })
}

export async function getSearchedDomainRecords(payload) {
    const url = "/api/v1/domain/registration/domain-name";
    return await osintAxios.post(url, payload, { headers: { "x-tool-name": "company-name", "x-case-id": payload.neo_case_id, "x-status": "RUNNING" } })
}

export async function updateCaseName(payload) {
    const url = `/case/${payload.neo_case_id}`;
    delete payload.neo_case_id
    return await delvAxios.put(url, payload, { headers: { "x-tool-name": "company-name", "x-case-id": payload.neo_case_id, "x-status": "RUNNING" } })
}

export async function getCompanyDetails(payload) {
    const url = "/api/v1/company/search/id";
    return await osintAxios.post(url, payload, { headers: { "x-tool-name": "company-name" } })
}
export async function getOwnershiDetails(payload) {
    const url = "/api/v1/company/search/chain";
    return await osintAxios.post(url, payload, { headers: { "x-tool-name": "company-name" } })
}

export async function getjurisdictionList() {
    const url = "/api/v1/company/search/jurisdiction?cache_bypass=false";
    return await osintAxios.get(url)
}


export async function updateEntity(payload, case_id) {
    const url = `/case/${case_id}/entities`;
    return await delvAxios.post(url, payload)
}

export async function getRunInteligenceDetails(payload) {
    const url = "/api/v1/socail-media-automation/case-run-intelligence";
    return await blackboxAxios.post(url, payload,)
}

export async function getDomainDetails(payload) {
    const url = "api/v1/domain/company"
    return await osintAxios.post(url, payload)
}


export async function createCase(payload) {
    const url = "/case/automated"
    return await delvAxios.post(url, payload)
}

export async function createNeotasCase(payload) {
    const url = "/api/v1/internal-service/case"
    return blackboxAxios.post(url, payload)
}

export async function getGlobalFilters(payload) {
    const url = "/api/v1/ner-analyzer/global-filter-extended"
    return blackboxAxios.post(url, payload, { headers: { "x-case-id": payload.case_id, "x-status": "RUNNING" } }).then(res => res.data.data)
}

export async function getInternetQueries(payload) {
    const url = "/api/v1/search-engine/queryset/queries"
    return blackboxAxios.post(url, payload, { headers: { "x-case-id": payload.case_id, "x-status": "RUNNING" } }).then(res => res.data)
}


