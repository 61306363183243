import { default as ax } from "axios";
import VueCookies from "vue-cookies";

// import store from "@/store";
// import { APP_CONFIG } from "@/utils/constants";
import refreshTokeninterceptor from "./utils.js";
import {getBaseUrlDelviumLightray} from "@shared/utils/functions"


let axios = ax.create({
    baseURL: getBaseUrlDelviumLightray(),
});


axios.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            /*
            To keep some apis working ex(case list api) which dependes upon some header values,
            use this function.
            */
            // config.headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
            // config.headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
            // config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, refreshTokeninterceptor(axios));
export default axios;
